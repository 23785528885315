import React from "react";
import "../stylesheets/About.css";
import Typography from "@mui/material/Typography";

const About = () => {
  const processDetails = [
    {
      title: "No Pre-Written Content",
      desc: "We start from scratch with each client, building content that is unique to your business industry and aesthetic. ",
    },
    {
      title: "Limitless Design",
      desc: "Don't settle for an off-the-shelf design. Customize each aspect of the webpage easily to get the look you desire.",
    },
    {
      title: "Code Ownership",
      desc: "We build the product but you own it. We hand over all the source code and it is yours to customize forever.",
    },
    {
      title: "Accountable Professionals",
      desc: "You will be connected with a project manager that will guide you through the development process from beginning to end.",
    },
    {
      title: "Transparent Pricing",
      desc: "No surprises here. The price you are quoted for your website is the price you will pay. Not a penny more. No hidden fees.",
    },
    {
      title: "Responsive Communication",
      desc: "Your feedback will be used to guide the look and functionality of your site. Expect to receive regular status updates on the progress of your app.",
    },
  ];

  const handleCardFlip = (e) => {
    e.currentTarget.classList.toggle("is-flipped");

    console.log(e.currentTarget);
  };

  const displayCard = (item) => {
    return (
      <div className="scene">
        <div className="card" onMouseEnter={(e) => handleCardFlip(e)}>
          <div variant="h5" className="face face-front">
            {item.title}
            <span className="circle"> </span>
          </div>
          <div className="face face-back">{item.desc}</div>
        </div>
      </div>
    );
  };

  return (
    <section className="about" id="about">
      <div className="headline">About The Syntax Lane Process</div>
      <Typography variant="h3">
        Founded by an Ivy League engineering grad who fell in love with web
        development, Syntax Lane is focused on providing a seamless experience
        for businesses wishing to make a statement on the web. Click on any of
        the cards below to learn more about our development process.
      </Typography>
      <div className="about-chart">
        {processDetails.map((item, idx) => {
          return <React.Fragment key={idx}>{displayCard(item)}</React.Fragment>;
        })}
      </div>
    </section>
  );
};

export default About;
