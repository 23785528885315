import React, { useEffect, useState } from "react";
import "../stylesheets/Services.css";
import { Typography } from "@mui/material";
import DiscussImg from "../assets/discuss.jpg";
import DefineImg from "../assets/define.jpg";
import DesignImg from "../assets/design.jpg";
import DevelopImg from "../assets/develop.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import StorageIcon from "@mui/icons-material/Storage";
import DvrIcon from "@mui/icons-material/Dvr";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";

const Services = () => {
  const [selectedMethod, setSelectedMethod] = useState(0);
  const methods = [
    {
      title: "01. Discuss",
      description:
        "We will begin with an interactive meeting to establish the scope, budget and timeline of our collaboration.",
      image: DiscussImg,
    },
    {
      title: "02. Define",
      description:
        "We will identify a strategic plan that outlines all the steps required to meet our goals on time & on budget.",
      image: DefineImg,
    },
    {
      title: "03. Design",
      description:
        "Your new website assets will be designed and presented to you for approval.",
      image: DesignImg,
    },
    {
      title: "04. Develop",
      description:
        "Your website will be coded according to your specifications, tested for functionality, and fully deployed to the web.",
      image: DevelopImg,
    },
  ];

  useEffect(() => {
    document
      .querySelectorAll(".method-title")
      .forEach((item) => (item.style.borderLeft = "3px solid transparent"));
    document.querySelector(`#method${selectedMethod}`).style.borderLeft =
      "3px solid white";
  }, [selectedMethod]);

  const serviceList = [
    {
      title: "Front-End Web Development",
      desc: "Client-side modules offering performant experiences & stress-free user interaction.",
      icon: <DvrIcon />,
    },
    {
      title: "Back-End Web Development",
      desc: "Server-side frameworks that facilitate communication between databases & apps.",
      icon: <StorageIcon />,
    },
    {
      title: "Modern UI / UX Design",
      desc: "Eye-grabbing, functional designs for desktop & mobile devices.",
      icon: <ViewQuiltIcon />,
    },
  ];

  const displayCard = (item) => {
    return (
      <Card className="card" sx={{ minWidth: 200, textAlign: "center" }}>
        <CardContent>
          <Typography
            sx={{ fontSize: "1.5rem" }}
            color="text.secondary"
            gutterBottom
          >
            {item.title}
          </Typography>
          {item.icon}
          <Typography variant="body2">{item.desc}</Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <section className="services" id="services">
      <div className="headline">
        Syntax Lane Web Design & Development Services
      </div>
      <div className="description">
        {/* We digital solutions & customer experiences. */}
      </div>
      <div className="method">
        {/* <Typography variant="h3">What I Offer</Typography> */}
        <div className="service-list">
          {serviceList.map((item, idx) => {
            return (
              <React.Fragment key={idx}>{displayCard(item)}</React.Fragment>
            );
          })}
        </div>
        <Typography variant="h3">The 4-D Method</Typography>
        <div className="method-chart">
          <div className="title-list">
            {methods.map((item, idx) => {
              return (
                <div
                  key={idx}
                  id={`method${idx}`}
                  className="method-title"
                  onMouseEnter={() => setSelectedMethod(idx)}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
          <div className="method-detail">
            <Card
              className="card"
              sx={{ minWidth: 200, maxWidth: 600, textAlign: "center" }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: "1.5rem" }}
                  color="text.secondary"
                  gutterBottom
                >
                  {methods[selectedMethod].title}
                </Typography>
                <img
                  className="method-image"
                  alt="business strategy"
                  src={methods[selectedMethod].image}
                />{" "}
                <Typography variant="body1">
                  {" "}
                  {methods[selectedMethod].description}
                </Typography>
              </CardContent>
            </Card>
            {/* <span>{methods[selectedMethod].description}</span>

            <img alt="business strategy" src={methods[selectedMethod].image} /> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
};

export default Services;
