import React from "react";
import { Link } from "react-scroll";
const Footer = () => {
  return (
    <div className="footer">
      <hr />
      <ul>
        <li>
          <span onClick={() => window.scrollTo(0, 0)}>Home</span>
        </li>
        <li>
          <Link to="about">About</Link>
        </li>
        <li>
          <Link to="contact">Contact</Link>
        </li>
        <li>
          <Link to="pricing">Pricing</Link>
        </li>
      </ul>
      <p>Copyright © {new Date().getFullYear()} Syntax Lane</p>
    </div>
  );
};

export default Footer;
